<template>
	<div>
		<v-toolbar dark color="secondary">
			<v-btn rounded dark text class="mr-2" :to="{ name: 'Clients' }"><v-icon>arrow_back</v-icon></v-btn>
			<v-toolbar-title class="white--text">{{ currentClient.name }}</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn icon rounded dark @click="refreshPage">
				<v-icon>refresh</v-icon>
			</v-btn>
			<v-btn dark text @click.native="deleteItem(currentClient._id)"><v-icon>delete</v-icon></v-btn>
			<v-btn dark text @click.native="saveItem(currentClient._id)"><v-icon>save</v-icon></v-btn>
		</v-toolbar>
		<v-container fluid>
			<v-tabs fixed-tabs background-color="primary" dark show-arrows>
				<v-tabs-slider></v-tabs-slider>
				<v-tab>Client Info</v-tab>
				<v-tab-item>
					<client-info-form v-model="currentClient" :stores="stores" :retailers="retailers"
						:auditors="auditors" :apps="apps" :rules="rules" ref="clientForm" />
				</v-tab-item>
				<v-tab>Device Status</v-tab>
				<v-tab-item>
					<client-device-status :client="currentClient" :devices="devices" :carriers="carrierList" />
				</v-tab-item>
				<v-tab>FCM Data Message</v-tab>
				<v-tab-item>
					<client-fcm-message :fcm-tokens="currentClient.fcmTokens || []" :project-ids="projectIds"
						@message-sent="fcmResult = $event"
						@message-error="error => console.error('FCM Error:', error)" />
				</v-tab-item>
				<v-tab>Installed Apps</v-tab>
				<v-tab-item>
					<client-installed-apps :apps="currentClient.appNames || []" />
				</v-tab-item>
				<v-tab>Feature Codes</v-tab>
				<v-tab-item>
					<client-feature-codes :features="currentClient.featureList" @update:feature="updateFeature" />
				</v-tab-item>
				<v-tab>Blocked Activities</v-tab>
				<v-tab-item>
					<client-blocked-activities :activities="activities" v-model="currentClient.activityBlocklist"
						@clear="clearActivityBlocklist" />
				</v-tab-item>
				<v-tab>Audit History</v-tab>
				<v-tab-item>
					<client-audit-history :client-id="$attrs.id" v-if="$attrs.id !== 'new'" />
				</v-tab-item>
			</v-tabs>
		</v-container>
		<save-progress-dialog v-model="showSaveDialog" :can-push-changes="canPushChanges" :is-saving="isSaving"
			:is-pushing="isPushing" :save-error="saveError" :push-error="pushError" @push-changes="pushChangesToDevice"
			@continue-editing="continueEditing" @navigate-to-clients="navigateToClients" />
	</div>
</template>
<script>
import { clientApi } from '../services/api'
import SaveProgressDialog from '../components/SaveProgressDialog.vue'
import ClientDeviceStatus from '../components/ClientDeviceStatus.vue'
import ClientFcmMessage from '../components/ClientFcmMessage.vue'
import ClientFeatureCodes from '../components/ClientFeatureCodes.vue'
import ClientBlockedActivities from '../components/ClientBlockedActivities.vue'
import ClientInstalledApps from '../components/ClientInstalledApps.vue'
import ClientInfoForm from '../components/ClientInfoForm.vue'
import ClientAuditHistory from '../components/ClientAuditHistory.vue';

const createDefaultClient = () => ({
	name: '',
	email: '',
	serialNo: '',
	imei: '',
	emailPackage: false,
	isActive: true,
	betaTester: false,
	whatsApp: false,
	sportsPackage: false,
	activityBlocklist: [],
	retailer: "5a9b713b960b423014504813",
	auditor: "610357773db92000416eff99",
	store: "5a0d9be20823dc23dfcacc18",
	featureList: {},
	altEmails: [],
})

export default {
	name: "ClientDetail",
	components: {
		SaveProgressDialog,
		ClientDeviceStatus,
		ClientFcmMessage,
		ClientFeatureCodes,
		ClientBlockedActivities,
		ClientInstalledApps,
		ClientInfoForm,
		ClientAuditHistory
	},
	data() {
		return {
			stores: [],
			apps: [],
			retailers: [],
			auditors: [],
			carrierList: [],
			devices: [],
			activities: [],
			currentClient: createDefaultClient(),
			fcmResult: null,
			projectIds: [],
			showSaveDialog: false,
			isSaving: false,
			isPushing: false,
			saveError: null,
			pushError: null,
			rules: {
				required: v => !!v || 'Field is required',
				email: v => {
					if (!v) return true // Allow empty
					const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
					return pattern.test(v) || 'Invalid email format'
				},
				serialNumber: v => {
					if (!v) return true // Allow empty
					return v.length >= 5 || 'Serial number must be at least 5 characters'
				},
				imei: v => {
					if (!v) return true // Allow empty
					return /^\d{15}$/.test(v) || 'IMEI must be 15 digits'
				}
			}
		}
	},
	props: ['retailer', 'auditor'],
	computed: {
		canPushChanges() {
			return this.currentClient.fcmTokens &&
				this.currentClient.fcmTokens.some(token =>
					token.app === 'net.safetelecom.shomer' && token.token
				)
		},

		shomerFcmToken() {
			if (!this.currentClient.fcmTokens) return null
			const token = this.currentClient.fcmTokens.find(token =>
				token.app === 'net.safetelecom.shomer'
			)
			return token ? token.token : null
		}
	},
	methods: {
		compareByName(a, b) {
			return a.name.localeCompare(b.name)
		},
		validateAndSanitizeInputs() {
			return this.$refs.clientForm.validate()
		},
		async getData() {
			try {
				const [
					appsResponse,
					storesResponse,
					devicesResponse,
					retailersResponse,
					auditorsResponse,
					carriersResponse
				] = await Promise.all([
					clientApi.getApps(this.retailer.accessLevel),
					clientApi.getStores(this.retailer.accessLevel),
					clientApi.getDevices(),
					clientApi.getRetailers(),
					clientApi.getAuditors(),
					clientApi.getCarriers()
				])

				this.apps = appsResponse.data
				this.stores = storesResponse.data
				this.devices = devicesResponse.data
				this.retailers = retailersResponse.data.sort(this.compareByName)
				this.auditors = auditorsResponse.data.sort(this.compareByName)
				this.carrierList = carriersResponse.data

				if (this.$attrs.id !== "new") {
					const { data } = await clientApi.getClient(this.$attrs.id)
					this.currentClient = data
				}
			} catch (error) {
				console.error('Error fetching data:', error)
			}
		},
		async deleteItem(id) {
			if (confirm('Are you sure you want to delete this item?')) {
				try {
					await clientApi.deleteClient(id)
					this.$router.push({ name: 'Clients' })
				} catch (error) {
					console.error('Error deleting client:', error)
				}
			}
		},
		async saveItem() {
			if (!this.validateAndSanitizeInputs()) return;

			this.showSaveDialog = true;
			this.isSaving = true;
			this.saveError = null;
			this.pushError = null;

			try {
				const userData = {
					userId: this.$auth.user.sub,
					userName: this.$auth.user.name
				};

				let response;
				if (this.$attrs.id !== "new") {
					response = await clientApi.updateClient(this.currentClient._id, {
						...this.currentClient,
						_audit: userData
					});
					this.currentClient = response.data;
				} else {
					response = await clientApi.createClient({
						...this.currentClient,
						_audit: userData
					});
					this.currentClient = response.data;
				}

				this.isSaving = false;
			} catch (error) {
				this.isSaving = false;
				this.saveError = error.response?.data?.message || error.message;
				console.error('Error saving client:', error);
			}
		},

		// Replace pushChangesToDevice with:
		async pushChangesToDevice() {
			this.isPushing = true
			this.pushError = null

			try {
				const response = await clientApi.sendFcmMessage({
					token: this.shomerFcmToken,
					projectId: this.projectIds[0],
					dataKey: 'action',
					dataValue: 'sync'
				})
				this.isPushing = false
				return response.data
			} catch (error) {
				this.isPushing = false
				this.pushError = error.response?.data?.message || error.message
			}
		},
		clearActivityBlocklist() {
			this.currentClient.activityBlocklist = [];
		},
		closeSaveDialog() {
			this.showSaveDialog = false
			this.isSaving = false
			this.isPushing = false
			this.saveError = null
			this.pushError = null
		},

		continueEditing() {
			this.closeSaveDialog()
		},

		navigateToClients() {
			this.closeSaveDialog()
			this.$router.push({ name: 'Clients' })
		},
		async refreshPage() {
			this.currentClient = createDefaultClient()
			await this.getData()
		},
		async getProjectIds() {
			try {
				const response = await clientApi.getProjectIds()
				this.projectIds = response.data
			} catch (error) {
				console.error('Error fetching project IDs:', error)
				this.projectIds = []
			}
		},
		updateFeature({ name, value }) {
			if (!this.currentClient.featureList) {
				this.$set(this.currentClient, 'featureList', {})
			}
			this.$set(this.currentClient.featureList, name, value)
		},
	},
	async created() {
		await this.getData()
		try {
			// Just get activities directly
			const activitiesResponse = await clientApi.getActivities()
			this.activities = activitiesResponse.data.sort(this.compareByName)

			// Get project IDs
			const projectResponse = await clientApi.getProjectIds()
			this.projectIds = projectResponse.data
		} catch (error) {
			console.error('Error loading additional data:', error)
		}
	}
}
</script>
