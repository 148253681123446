import Vue from "vue";
import VueRouter from "vue-router";
import { authGuard } from "./auth/authGuard";

import ActivitiesRoute from "@/routes/Activities";
import ActivityRoute from "@/routes/Activity";
import ActivityGroupsRoute from "@/routes/ActivityGroups";
import ActivityGroupRoute from "@/routes/ActivityGroup";
import AppsRoute from "@/routes/Apps";
import AppRoute from "@/routes/App";
import ClientRoute from "@/routes/Client";
import ClientsRoute from "@/routes/Clients";
import DuplicatesRoute from "@/routes/Duplicates";
import StoresRoute from "@/routes/Stores";
import StoreRoute from "@/routes/Store";
import DevicesRoute from "@/routes/Devices";
import DeviceRoute from "@/routes/Device";
import RetailersRoute from "@/routes/Retailers";
import RetailerRoute from "@/routes/Retailer";
import UpdatesRoute from "@/routes/Updates";
import UpdateRoute from "@/routes/Update";
import MultiClientRoute from "@/routes/MultiClient";
import AuditorsRoute from "@/routes/Auditors";
import AuditorRoute from "@/routes/Auditor";
import ReportsRoute from "@/routes/Reports";
import BannedAppsRoute from "@/routes/BannedApps";
import UrlsRoute from "@/routes/Urls";
import UrlRoute from "@/routes/Url";
import OrdersRoute from "@/routes/Orders";
import FindDeviceRoute from "@/routes/FindDevice";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    { path: "/", redirect: "/clients" },
    {
      path: "/clients/:id",
      name: "Client",
      props: true,
      component: ClientRoute,
      beforeEnter: authGuard,
    },
    {
      path: "/clients",
      name: "Clients",
      component: ClientsRoute,
      beforeEnter: authGuard,
    },
    {
      path: "/duplicates",
      name: "Duplicates",
      component: DuplicatesRoute,
      beforeEnter: authGuard,
    },
    {
      path: "/stores/:store",
      name: "Store",
      props: true,
      component: StoreRoute,
      beforeEnter: authGuard,
    },
    {
      path: "/stores",
      name: "Stores",
      component: StoresRoute,
      beforeEnter: authGuard,
    },
    {
      path: "/devices/:id",
      name: "Device",
      props: true,
      component: DeviceRoute,
      beforeEnter: authGuard,
    },
    {
      path: "/devices",
      name: "Devices",
      component: DevicesRoute,
      beforeEnter: authGuard,
    },
    {
      path: "/retailers/:retailer",
      name: "Retailer",
      props: true,
      component: RetailerRoute,
      beforeEnter: authGuard,
    },
    {
      path: "/retailers",
      name: "Retailers",
      component: RetailersRoute,
      beforeEnter: authGuard,
    },
    {
      path: "/updates/:update",
      name: "Update",
      props: true,
      component: UpdateRoute,
      beforeEnter: authGuard,
    },
    {
      path: "/updates",
      name: "Updates",
      component: UpdatesRoute,
      beforeEnter: authGuard,
    },
    {
      path: "/apps/:id",
      name: "App",
      component: AppRoute,
      beforeEnter: authGuard,
    },
    {
      path: "/apps",
      name: "Apps",
      component: AppsRoute,
      beforeEnter: authGuard,
    },
    {
      path: '/multi-client',
      name: 'MultiClient',
      component: MultiClientRoute,
      beforeEnter: authGuard,
    },
    {
      path: "/activities/:id",
      name: "Activity",
      component: ActivityRoute,
      beforeEnter: authGuard,
    },
    {
      path: "/activities",
      name: "Activities",
      component: ActivitiesRoute,
      beforeEnter: authGuard,
    },
    {
      path: "/activitygroups/:id",
      name: "ActivityGroup",
      component: ActivityGroupRoute,
      beforeEnter: authGuard,
    },
    {
      path: "/activitygroups",
      name: "ActivityGroups",
      component: ActivityGroupsRoute,
      beforeEnter: authGuard,
    },
    {
      path: "/auditors/:auditor",
      name: "Auditor",
      props: true,
      component: AuditorRoute,
      beforeEnter: authGuard,
    },
    {
      path: "/auditors",
      name: "Auditors",
      component: AuditorsRoute,
      beforeEnter: authGuard,
    },
    {
      path: "/reports",
      name: "Reports",
      component: ReportsRoute,
      beforeEnter: authGuard,
    },
    {
      path: "/banned-apps",
      name: "BannedApps",
      component: BannedAppsRoute,
      beforeEnter: authGuard,
    },
    {
      path: '/urls',
      name: "Urls",
      component: UrlsRoute,
      beforeEnter: authGuard
    },
    {
      path: '/urls/:id',
      name: "Url",
      component: UrlRoute
    },
    {
      path: '/orders',
      name: "Orders",
      component: OrdersRoute,
      beforeEnter: authGuard
    },
    {
      path: "/find",
      name: "Find Device",
      component: FindDeviceRoute,
      beforeEnter: authGuard,
    },
    {
      path: '/audit-logs',
      name: 'AuditLogs',
      component: () => import('@/routes/AuditLogs.vue'),
      meta: {
        requiresAuth: true
      }
    }
  ],
});

export default router;
