<template>
	<v-app>
		<v-navigation-drawer app router v-model="drawer" clipped>
			<v-list>
				<v-subheader>Account</v-subheader>
				<v-list-item>
					<v-list-item-avatar>
						<img :src="$auth.user.picture" alt="">
					</v-list-item-avatar>
					<v-list-item-content>
						<v-list-item-title>{{ $auth.user.name }}</v-list-item-title>
						<v-list-item-subtitle>{{ $auth.user.email }}</v-list-item-subtitle>
						<v-list-item-subtitle>Access Level: {{ retailer.accessLevel }}</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<template>
				<div class="pa-2" v-if="!$auth.loading">
					<v-btn color="primary" block v-if="!$auth.isAuthenticated" @click="login">Login</v-btn>
					<v-btn color="primary" block v-if="$auth.isAuthenticated" @click="logout">Logout</v-btn>
				</div>
			</template>
			<v-divider></v-divider>
			<v-list>
				<v-subheader>Navigation</v-subheader>
				<template v-for="item in items">
					<v-list-item :to="item.path" :key="item.text">
						<v-list-item-action>
							<v-icon>{{ item.icon }}</v-icon>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>
								{{ item.text }}
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</template>
			</v-list>
		</v-navigation-drawer>
		<v-app-bar color="blue darken-3" dark app clipped-left>
			<v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
			<v-toolbar-title style="width: 300px" class="ml-0 pl-4">
				<span>SafeTelecom Admin</span>
			</v-toolbar-title>
		</v-app-bar>
		<v-main>
			<router-view :retailer="retailer" />
		</v-main>
	</v-app>
</template>

<script>
export default {
	name: 'App',
	data() {
		return {
			drawer: null,
			items: [
				{ icon: 'apps', text: 'Apps', path: '/apps' },
				{ icon: 'people', text: 'Clients', path: '/clients' },
				{ icon: 'people', text: 'Multi Client', path: '/multi-client' },
				{ icon: 'mdi-basket', text: 'Orders', path: '/orders' },
				{ icon: 'content_copy', text: 'Duplicates', path: '/duplicates' },
				{ icon: 'devices', text: 'Devices', path: '/devices' },
				{ icon: 'store', text: 'Retailers', path: '/retailers' },
				{ icon: 'person', text: 'Auditors', path: '/auditors' },
				{ icon: 'local_grocery_store', text: 'Stores', path: '/stores' },
				{ icon: 'system_update', text: 'Updates', path: '/updates' },
				{ icon: 'snowboarding', text: 'Activities', path: '/activities' },
				{ icon: 'ac_unit', text: 'Activity Groups', path: '/activitygroups' },
				{ icon: 'pie_chart', text: 'Reports', path: '/reports' },
				{ icon: 'block', text: 'Banned Apps', path: '/banned-apps' },
				{ icon: 'mdi-link', text: 'URLs', path: '/urls' },
				{ icon: 'mdi-clipboard-text-clock', text: 'Audit Logs', path: '/audit-logs' }  // Added this line
			]
		}
	},
	computed: {
		retailer() {
			if (!this.$auth.loading) {
				return this.$auth.claims['http://safetelecom/safetelecom']
			}
			return {}
		}
	},
	methods: {
		login() {
			this.$auth.loginWithRedirect()
		},
		logout() {
			this.$auth.logout({
				returnTo: window.location.origin
			})
		}
	}
}
</script>
